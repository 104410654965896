import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {FullPageSpinner} from "./components/common/Spinner";
import {withAITracking} from '@microsoft/applicationinsights-react-js';
import {applicationInsights} from "./commons";

const Counter = React.lazy(() => import("./components/Counter"));
const Home = React.lazy(() => import('./components/Home'));
const UserDetails = React.lazy(() => import('./components/UserDetails'));
const GreenIdVerificationPage = React.lazy(() => import('./components/greenid/GreenIdVerificationPage'));
const GreenIdVerificationLandingPage = React.lazy(() => import('./components/greenid/GreenIdVerificationLanding'));
const ErrorPage = React.lazy(() => import('./components/ErrorPage'));
const ViewBetsLanding = React.lazy(() => import('./components/viewbets/ViewBetsLandingPage'));
const ResetPassword = React.lazy(() => import('./components/resetpassword/ResetPassword'));

const DepositLanding = React.lazy(() => import('./components/deposit/DepositLanding'));
const FatZebraPage = React.lazy(() => import('./components/FatZebra'));
// const FatZebraDepositPage = React.lazy(() => import('./components/deposit/FatZebraDepositPage'));
const FatZebraDeposit = React.lazy(() => import('./components/deposit/FatZebraDeposit'));

function App() {
  return (
    <React.Suspense fallback={<FullPageSpinner/>}>
      <Switch>
        <Route exact path="/error" name="Error" render={props => <ErrorPage {...props}/>}/>
        <Route exact path="/reset-password/:token" name="ResetPassword" render={props => <ResetPassword {...props}/>}/>
        <Route path="/verification/:token" name="GreenIdVerification"
               render={props => <GreenIdVerificationLandingPage {...props}/>}/>
        <Route path="/viewbets/:betId" name="ViewBetsLanding"
               render={props => <ViewBetsLanding {...props}/>}/>
        <Route path="/deposit/:token/:currency/:amount" name="DepositLanding"
               render={props => <DepositLanding {...props}/>}/>
        {/* <Route path="/deposit" name="Deposit"
               render={props => <FatZebraDepositPage {...props}/>}/> */}
        <Route path="/deposit-fz/:amount" name="Deposit"
               render={props => <FatZebraDeposit {...props}/>}/>

        <Route path="/verification" name="GreenIdVerification"
               render={props => <GreenIdVerificationPage {...props}/>}/>
        <Route exact path="/counter" name="Counter" render={props => <Counter {...props}/>}/>
        <Route exact path="/user" name="User" render={props => <UserDetails {...props}/>}/>
        <Route exact path="/fz" name="FatZebra" render={props => <FatZebraPage {...props}/>}/>
        <Route exact path="/" name="Home" render={props => <Home {...props}/>}/>
      </Switch>
    </React.Suspense>
  );
}

export default withAITracking(applicationInsights.reactPlugin, App);
