import config from './config';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

export const env = process.env.REACT_APP_BB_ENV;
export const apiUrl = config.API_DOMAIN.trim(); // + '/api/json/reply';
export const imageDomain = config.IMGIX_DOMAIN.trim();
export const fzScriptUrl = config.FZ_SCRIPT;
export const fzUsername = config.FZ_USERNAME;

export const saveToken = token => lsSave(config.LOCAL_STORAGE_TOKEN_KEY, token);
export const getToken = () => lsGet(config.LOCAL_STORAGE_TOKEN_KEY);
export const clearToken = () => lsClear(config.LOCAL_STORAGE_TOKEN_KEY);

export const lsSave = (key, value) => localStorage.setItem(key, value);
export const lsGet = (key) => localStorage.getItem(key);
export const lsClear = (key) => localStorage.removeItem(key);

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: config.AI_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      //[reactPlugin.identifier]: {history}
    }
  }
});

export const applicationInsights = {reactPlugin, appInsights};

export const fetchPost = async (url, body) => {
  let response, error;
  try {
    const fetchTask = await fetch(url, {
      headers: {'Content-Type': 'application/json', 'Authorization': getToken()},
      method: 'POST',
      body: JSON.stringify(body)
    });
    if (fetchTask.status > 400) throw new Error(fetchTask.statusText);
    response = await fetchTask.json();
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

export const fetchGet = async (url) => {
  let response, error;
  try {
    const fetchTask = await fetch(url, {
      headers: {'Authorization': getToken()},
      mode: 'cors'
    });
    if (!fetchTask.ok)
      error = fetchTask.statusText;
    else
      response = await fetchTask.json();
  } catch (e) {
    error = e.message;
  }
  return [error, response];
};

