// This is also prod config, add it here first and override in other env if needed
// https://bbpreprodcliapi.buddybet.com 
// API_DOMAIN: "https://bbprodcliapi-prem-fun.buddybet.com/",--Prod old

const commonConfig = {   
  API_DOMAIN: "https://cliapi.buddybet.com/clientapi/v1",
  GID_UI_JS: "https://simpleui-au.vixverify.com/df/javascripts/greenidui.min.js",
  GID_CONFIG_JS: "https://simpleui-au.vixverify.com/df/javascripts/greenidConfig.js",
  GID_CSS: "https://simpleui-au.vixverify.com/df/assets/stylesheets/greenid.css",
  GID_ENV: "prod",
  GID_PASSWORD: "JhB-bjx-76t-FP2",
  GID_ACCOUNT: "buddybet",
  LOCAL_STORAGE_TOKEN_KEY: "token",
  IMGIX_DOMAIN: "https://buddy-bet.imgix.net",
  WEBDOSH_ENDPOINT: "https://api.test.webdosh.com/post2",
  AI_KEY: "6c37959d-dee1-4fe8-bfef-9111824a72d6",
  FZ_SCRIPT: "https://cdn.pmnts.io/sdk/v1/fatzebra.js",
  FZ_USERNAME: "buddybet",

};

const devConfig = {
  // API_DOMAIN: "http://localhost:7071",
  API_DOMAIN: "https://devkong.buddybet.com/clientapidev/v1",
  WEBDOSH_ENDPOINT: "https://api.test.webdosh.com/post2",
  AI_KEY: "1c1ef457-9080-4816-ab3f-b873ace56d88",
  GID_UI_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidui.min.js",
  GID_CONFIG_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidConfig.js",
  GID_CSS: "https://simpleui-test-au.vixverify.com/df/assets/stylesheets/greenid.css",
  GID_ENV: "test",
  GID_PASSWORD: "uH4-Atj-XVF-9wG",
  GID_ACCOUNT: "buddybet",
  FZ_SCRIPT: "https://cdn.pmnts-sandbox.io/sdk/v1/fatzebra.js",
  FZ_USERNAME: "TESTbuddybet",
};

const azureDevConfig = {
  API_DOMAIN: "https://devkong.buddybet.com/clientapidev/v1",
  WEBDOSH_ENDPOINT: "https://api.test.webdosh.com/post2",
  AI_KEY: "1c1ef457-9080-4816-ab3f-b873ace56d88",
  GID_UI_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidui.min.js",
  GID_CONFIG_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidConfig.js",
  GID_CSS: "https://simpleui-test-au.vixverify.com/df/assets/stylesheets/greenid.css",
  GID_ENV: "test",
  GID_PASSWORD: "uH4-Atj-XVF-9wG",
  GID_ACCOUNT: "buddybet",
  FZ_SCRIPT: "https://cdn.pmnts-sandbox.io/sdk/v1/fatzebra.js",
  FZ_USERNAME: "TESTbuddybet",
};

const testConfig = {
  API_DOMAIN: "https://devkong.buddybet.com/clientapiuatext/v1",
  WEBDOSH_ENDPOINT: "https://api.test.webdosh.com/post2",
  AI_KEY: "a55e9941-cd55-4dec-a9a8-a07aa3ce936b",
  GID_UI_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidui.min.js",
  GID_CONFIG_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidConfig.js",
  GID_CSS: "https://simpleui-test-au.vixverify.com/df/assets/stylesheets/greenid.css",
  GID_ENV: "test",
  GID_PASSWORD: "uH4-Atj-XVF-9wG",
  GID_ACCOUNT: "buddybet",
  FZ_SCRIPT: "https://cdn.pmnts-sandbox.io/sdk/v1/fatzebra.js",
  FZ_USERNAME: "TESTbuddybet",
};

const uatConfig = {
  
  API_DOMAIN: "https://devkong.buddybet.com/clientapiuat/v1",
  WEBDOSH_ENDPOINT: "https://api.test.webdosh.com/post2",
  AI_KEY: "a55e9941-cd55-4dec-a9a8-a07aa3ce936b",
  GID_UI_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidui.min.js",
  GID_CONFIG_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidConfig.js",
  GID_CSS: "https://simpleui-test-au.vixverify.com/df/assets/stylesheets/greenid.css",
  GID_ENV: "test",
  GID_PASSWORD: "uH4-Atj-XVF-9wG",
  GID_ACCOUNT: "buddybet",
  FZ_SCRIPT: "https://cdn.pmnts-sandbox.io/sdk/v1/fatzebra.js",
  FZ_USERNAME: "TESTbuddybet",
};

const qaConfig = {
  API_DOMAIN: "https://devkong.buddybet.com/clientapiqa/v1",
  WEBDOSH_ENDPOINT: "https://api.test.webdosh.com/post2",
  AI_KEY: "1c1ef457-9080-4816-ab3f-b873ace56d88",
  GID_UI_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidui.min.js",
  GID_CONFIG_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidConfig.js",
  GID_CSS: "https://simpleui-test-au.vixverify.com/df/assets/stylesheets/greenid.css",
  GID_ENV: "test",
  GID_PASSWORD: "uH4-Atj-XVF-9wG",
  GID_ACCOUNT: "buddybet",
  FZ_SCRIPT: "https://cdn.pmnts-sandbox.io/sdk/v1/fatzebra.js",
  FZ_USERNAME: "TESTbuddybet",
};

const preprodConfig = {
  API_DOMAIN: "https://devkong.buddybet.com/clientapi/v1",
  WEBDOSH_ENDPOINT: "https://api.test.webdosh.com/post2",
  AI_KEY: "1c1ef457-9080-4816-ab3f-b873ace56d88",
  GID_UI_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidui.min.js",
  GID_CONFIG_JS: "https://simpleui-test-au.vixverify.com/df/javascripts/greenidConfig.js",
  GID_CSS: "https://simpleui-test-au.vixverify.com/df/assets/stylesheets/greenid.css",
  GID_ENV: "test",
  GID_PASSWORD: "uH4-Atj-XVF-9wG",
  GID_ACCOUNT: "buddybet",
  FZ_SCRIPT: "https://cdn.pmnts-sandbox.io/sdk/v1/fatzebra.js",
  FZ_USERNAME: "TESTbuddybet",
}; 


const env = process.env.REACT_APP_BB_ENV;

export default env === "production" ? commonConfig
  : env === "uat" ? {...commonConfig, ...uatConfig}
   : env === "qa" ? {...commonConfig, ...qaConfig}
    : env === "test" ? {...commonConfig, ...testConfig}
      : env === "azuredev" ? {...commonConfig, ...azureDevConfig}
        : env === "preproduction" ? {...commonConfig, ...preprodConfig}
          : env === "development" ? {...commonConfig, ...devConfig}
            : commonConfig;
